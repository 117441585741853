import { useMemo } from 'react'
import { Column } from 'react-table'
import { useTranslation } from 'react-i18next'
import format from 'date-fns/format'
import classNames from 'classnames'
import styles from './SellTable.module.scss'
import { Table as CustomTable } from '../../../../shared/components/table'
import PrimaryButton from '../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import { useApproveMutation, useMySellsQuery } from '../../queries'
import { dateFromDotDateTimeFormat } from '../../../../shared/utils/date'
import { DOT_DATE_TIME_FORMAT } from '../../../../shared/constants/date'
import { useLanguageSwitcherContext } from '../../../languageSwitcher'
import DeclineOrder from '../DeclineOrder/DeclineOrder'
import { TMySell, TStatus } from '../../types'
import { addZeros } from '../../../../shared/utils/formUtils'

const statusMap: {[key in TStatus]: string} = {
  NEGOTIATING: 'negotiating',
  CONFIRMED: 'confirmed',
  DECLINED: 'declined'
}

const SellTable = () => {
  const { data } = useMySellsQuery()
  const { language } = useLanguageSwitcherContext()
  const { t } = useTranslation()
  const { mutate } = useApproveMutation()
  const columns = useMemo<Column[]>(() =>
    [
      {
        Header: <div className={classNames(styles.tableHeader, styles.bold)}>{t('sell.list.table.product')}</div>,
        accessor: 'product',
        Cell: ({ value }) => (
          <div className={classNames(styles.tableCell, styles.productCell)}>
            {value.name}
          </div>
        )
      },
      {
        Header: <div className={classNames(styles.tableHeader, styles.bold)}>{t('sell.list.table.label')}</div>,
        accessor: 'label',
        Cell: ({ value }) => (
          <div className={styles.tableCell}>
            {value.name}
          </div>
        )
      },
      {
        Header: <div className={classNames(styles.tableHeader, styles.bold)}>{t('sell.list.table.minPrice')}</div>,
        accessor: 'minPricePerCu',
        Cell: ({ value, row }) => {
          const rowOriginal = row.original as TMySell

          return (
            <div className={styles.tableCell}>
              {value
                ? `${t('common.chf')} ${addZeros(value)} / ${addZeros(rowOriginal.bidPricePerCu)}`
                : '-'}
            </div>
          )
        }
      },
      {
        Header: <div className={classNames(styles.tableHeader, styles.bold, styles.amountTuHeader)}>{t('sell.list.table.amountInTU')}</div>,
        accessor: 'amountTu',
        Cell: ({ value }) => <div className={styles.tableCell}>{value}</div>
      },
      {
        Header: <div className={classNames(styles.tableHeader, styles.bold)}>{t('sell.list.table.totalPriceBid')}</div>,
        accessor: 'totalInclVat',
        Cell: ({ value }) => <div className={styles.tableCell}>{`${t('common.chf')} ${addZeros(value)}`}</div>
      },
      {
        Header: <div className={classNames(styles.tableHeader, styles.bold)}>{t('sell.list.table.buyer')}</div>,
        accessor: 'buyer',
        Cell: ({ value }) => (
          <div
            className={classNames(styles.tableCell, styles.buyerCell)}>
            {value.name}
          </div>
        )
      },
      {
        Header: <div className={classNames(styles.tableHeader, styles.bold)}>{t('sell.list.table.orderDate')}</div>,
        accessor: 'dateTime',
        Cell: ({ value }) => (
          <div className={styles.tableCell}>
            {value?format(dateFromDotDateTimeFormat(value), DOT_DATE_TIME_FORMAT):'-'}
          </div>
        ) },
      {
        Header: <div className={classNames(styles.tableHeader, styles.bold, styles.statusHeader)}>{t('sell.list.table.status')}</div>,
        accessor: 'status',
        Cell: ({ value }) => {
          const statusValue = value as TStatus
          return (
            <span
              className={classNames(
                styles.tableCell,
                styles.status,
                styles[statusMap[statusValue]]
              )}>
              {t(`sell.status.${statusValue === 'NEGOTIATING' ? 'IN_NEGOTIATION' : statusValue}`)}
            </span>
          )
        }
      },
      {
        Header: <div className={styles.tableHeader}>{t('sell.list.table.actions')}</div>,
        accessor: 'id',
        Cell: ({ value, row }) => {
          const { status, amountTu, availableAmount } = row.original as TMySell
          const isNegotiating = status === 'NEGOTIATING'
          const isApproveDisabled = amountTu > availableAmount
          if (isNegotiating) {
            return (
              <div className={styles.buttonGroup}>
                <PrimaryButton
                  disabled={isApproveDisabled}
                  onClick={() => mutate(value)}
                  className={styles.button}
                  text={t('common.approve')}/>
                <DeclineOrder id={value}/>
              </div>
            )
          }
          return <></>
        }
      }

    ], [language])
  return (
    <div className={styles.tableWrapper}>
      <CustomTable
        contentClass={styles.table}
        data={data || []}
        columns={columns}
      />
    </div>
  )
}

export default SellTable
