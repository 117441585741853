import { format } from 'date-fns'
import { TForm, TInnerCirceListFinder, TInnerCircle, TOfferParams } from '../types'
import { dotDateFormat } from '../../../../shared/utils/date'
import { ISO_DOT_DATE_TIME_FORMAT } from '../../../../shared/constants/date'

export const prepareOffer = (
  data: TForm
): Partial<TOfferParams> => {
  const {
    amountCuPerTu,
    amountKgPerCu,
    amountTuPerLu,
    amountTu,
    minAmountTu,
    pricePerCu,
    minPricePerCu,
    discount,
    minDiscountAmountTu,
    packageDate,
    bestBefore,
    negotiation,
    anonymous,
    innerCircle,
    file,
    pickUpDateTime,
    ...rest
  } = data

  return {
    amountCuPerTu: Number(amountCuPerTu),
    amountKgPerCu: Number(amountKgPerCu),
    amountTuPerLu: Number(amountTuPerLu),
    amountTu: Number(amountTu),
    minAmountTu: Number(minAmountTu),
    pricePerCu: pricePerCu ? Number(pricePerCu) : undefined,
    minPricePerCu: minPricePerCu ? Number(minPricePerCu) : undefined,
    discount: discount ? Number(discount) : undefined,
    minDiscountAmountTu: minDiscountAmountTu ? Number(minDiscountAmountTu) : undefined,
    packageDate: packageDate ? dotDateFormat(packageDate) : null,
    bestBefore: bestBefore ? dotDateFormat(bestBefore) : null,
    negotiation: negotiation === 'YES',
    anonymous: anonymous === 'YES',
    pickUpDateTime: pickUpDateTime?format(pickUpDateTime, ISO_DOT_DATE_TIME_FORMAT):null,
    ...rest
  }
}

export function prepareInnerCircles(
  data: TInnerCirceListFinder[],
  formInnerCircleData: string[]
) {
  const innerCircleListIds = data.map(({ id }) => id)
  return formInnerCircleData.reduce((acc, curr) => {
    if (innerCircleListIds.includes(curr)) {
      acc.innerCircleIds.push(curr)
    } else {
      acc.companyIds.push(curr)
    }
    return acc
  }, {
    innerCircleIds: [],
    companyIds: []
  } as TInnerCircle)
}
