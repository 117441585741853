import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import { Control, Controller } from 'react-hook-form'
import { FC } from 'react'
import { InputAdornment, TextField } from '@mui/material'
import { DefaultTFuncReturn } from 'i18next'
import en from 'date-fns/locale/en-US'
import fr from 'date-fns/locale/fr'
import de from 'date-fns/locale/de'
import it from 'date-fns/locale/it'
import styles from './CustomDateTimePicker.module.scss'
import { useValidationRules } from '../../../hooks/useValidationRules'
import { DOT_DATE_TIME_FORMAT } from '../../../constants/date'
import DateIcon from '../../icons/DateIcon'
import { TLanguage } from '../../../../features/account/accountBase'
import { useLanguageSwitcherContext } from '../../../../features/languageSwitcher'

type TProps = {
  control: Control<any>
  name: string
  label?: DefaultTFuncReturn
}
const locales: {[key in TLanguage]: Locale} = {
  en, fr, de, it
}
const CustomDateTimePicker: FC<TProps> = ({ control, name, label }) => {
  const {  dateValidation } = useValidationRules()
  const { language } = useLanguageSwitcherContext()
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={locales[language]}>
      <Controller
        control={control}
        name={name}
        rules={{
        //  required: requiredRule(),
          validate: dateValidation
        }}
        render={(({
          field: { onChange, value }, fieldState: { error }
        }) => (
          <div>
            {label && <p className={styles.label}>{label}</p>}
            <DateTimePicker
             // {...field}
              components={{
                OpenPickerIcon: DateIcon
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end"><DateIcon /></InputAdornment>
              }}
              inputFormat={DOT_DATE_TIME_FORMAT}
              onChange={onChange}
              value={value || ''}
              ampm={false}
              ampmInClock={false}
              views={['year', 'month', 'day', 'hours', 'minutes']}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: DOT_DATE_TIME_FORMAT
                  }}
                  className={styles.dateTime}
                  error={!!error?.message}
                  helperText={error?.message}
                />
              )}
            />
          </div>
        ))}
      />
    </LocalizationProvider>
  )
}
export default CustomDateTimePicker
